$(document).ready ->
    $('.slick').slick
        dots: true
        arrows: false
    if localStorage.getItem('visibility-mode')
        $('body').toggleClass 'visibility-mode'
    $(window).scroll ->
        if $(this).scrollTop() > 160
            $('body').addClass 'scroll'
        else
            $('body').removeClass 'scroll'
        return
    $('.flag-container').on 'click', ->
        $('.flag-container').toggleClass('open')

    #    $('.flag-container').on 'mouseleave', ->
    #        $('.flag-container').removeClass('open')

    $('.close-cookie').on 'click', ->
        localStorage.setItem 'cookieHide', 'yes'
        $('.cookie-alert').addClass 'd-none'
        return
    if localStorage.getItem('cookieHide') == 'yes'
        console.log 'hide'
        $('.cookie-alert').addClass 'd-none'
    $('.toggle-visibility').on 'click', ->
        $('body').toggleClass 'visibility-mode'
        if $('body').hasClass('visibility-mode')
            localStorage.setItem 'visibility-mode', true
        else
            localStorage.removeItem 'visibility-mode'
        return
    $('.extended-toggler').on 'click', ->
        target = undefined
        target = $(this).data('target')
        $('[data-target="' + target + '"]').toggleClass 'd-none'
        $('[id*="' + target + '_tgl"]').toggleClass 'd-none'
    $('.btn-addons-toggle').on 'click', ->
        $('.hideable').toggleClass 'd-none'
        $('.btn-addons-toggle').toggleClass 'd-none'
    handleForm = ()->
        $('#zgloszenie-platne-wielkoscPrzedsiebiorcy').parent('div').parent('div').addClass 'd-none'
        $('#zgloszenie-platne-email').parent('div').parent('div').addClass 'd-none'
        $('#zgloszenie-platne-id-wsparcia').parent('div').parent('div').addClass 'd-none'
        $('#zgloszenie-platne input[type=radio][name*="faktura"]').change ()->
            if (this.value == "Tak")
                $('#zgloszenie-platne-email').parent('div').parent('div').removeClass 'd-none'
            else
                $('#zgloszenie-platne-email').parent('div').parent('div').addClass 'd-none'
        $('#zgloszenie-platne input[type=radio][name*="cennik"]').change ()->
            if (this.value.indexOf('(BUR)') > 0)
                $('#zgloszenie-platne-id-wsparcia').parent('div').parent('div').removeClass 'd-none'
            else
                $('#zgloszenie-platne-id-wsparcia').parent('div').parent('div').addClass 'd-none'
        $('input[type=radio][name*="typKlienta"]').change ()->
            if (this.value == "przedsiębiorca")
                $('#zgloszenie-platne-wielkoscPrzedsiebiorcy').parent('div').parent('div').removeClass 'd-none'
            else
                $('#zgloszenie-platne-wielkoscPrzedsiebiorcy').parent('div').parent('div').addClass 'd-none'
    handleForm()
    handleAddress = () ->
        $('.form-control[name*="ulica"]').on('keyup', ()->
            localStorage.setItem('ulica', $(this).val())
        )
        $('.form-control[name*="nrdomu"]').on('keyup', ()->
            localStorage.setItem('nrdomu', $(this).val())
        )
        $('.form-control[name*="nrLokalu"]').on('keyup', ()->
            localStorage.setItem('nrLokalu', $(this).val())
        )
        $('.form-control[name*="kodPocztowy"]').on('keyup', ()->
            localStorage.setItem('kodPocztowy', $(this).val())
        )
        $('.form-control[name*="Miejscowosc"]').on('keyup', ()->
            localStorage.setItem('Miejscowosc', $(this).val())
        )

        if ($('.form-control[name*="ulica-1"]').val() == '')
            $('.form-control[name*="ulica-1"]').val(localStorage.getItem('ulica'))

        if ($('.form-control[name*="nrdomu-1"]').val() == '')
            $('.form-control[name*="nrdomu-1"]').val(localStorage.getItem('nrdomu'))

        if ($('.form-control[name*="nrLokalu-1"]').val() == '')
            $('.form-control[name*="nrLokalu-1"]').val(localStorage.getItem('nrLokalu'))

        if ($('.form-control[name*="kodPocztowy-1"]').val() == '')
            $('.form-control[name*="kodPocztowy-1"]').val(localStorage.getItem('kodPocztowy'))

        if ($('.form-control[name*="Miejscowosc-1"]').val() == '')
            $('.form-control[name*="Miejscowosc-1"]').val(localStorage.getItem('Miejscowosc'))


    handleAddress()
    createField = (id, name = '', phone = '', position = '', email = '', birthday = '', nameday = '', interests = [])->
        singleInput = '<div class="user-row mb-2" data-id="' + id + '">'
        singleInput += '<div class="form-group align-self-center"><input class="form-control" minlength="4" maxlength="30"  type="text" id="user_name_' + id + '"  placeholder="Imię i nazwisko reprezentanta" required="required" value="' + name + '"/></div>'
        singleInput += '<div class="form-group align-self-center "><input type="text"  minlength="4" maxlength="30" class="form-control" id="user_position_' + id + '"  placeholder="Stanowisko reprezentanta" required="required"  value="' + position + '"/></div>'
        singleInput += '<div class="form-group align-self-center "><input type="text" minlength="9" maxlength="10" size="10"  class="form-control" id="user_phone_' + id + '"  placeholder="Nr telefonu reprezentanta" required="required"  value="' + phone + '"/></div>'
        singleInput += '<div class="form-group align-self-center "><input type="email"  class="form-control" id="user_email_' + id + '"  placeholder="Email reprezentanta" required="required"  value="' + email + '"/></div>'
        singleInput += '<div class="form-group align-self-center "><input type="text" minlength="4" maxlength="15" class="form-control" id="user_birthday_' + id + '"  placeholder="Data urodzenia reprezentanta dd-mm-rrrr"  value="' + birthday + '"/></div>'
        singleInput += '<div class="form-group align-self-center "><input type="text" minlength="4" maxlength="15" class="form-control" id="user_nameday_' + id + '"  placeholder="Imieniny reprezentanta dd-mm"   value="' + nameday + '"/></div>'
        singleInput += '<div class="form-group align-self-center"><label>Wybrane grupy tematyczne<span class="required">*</span></label><div class="input"><ul class="inputs-list"><li><label><input type="checkbox" name="user_interests_' + id + '[]" value="ZASOBY LUDZKIE FINANSE" '+( if interests.includes("ZASOBY LUDZKIE FINANSE") then 'checked="checked"' else '')+'><span class="ml-3">ZASOBY LUDZKIE FINANSE</span></label></li><li><label><input type="checkbox" name="user_interests_' + id + '[]" value="INNOWACJE  B+R  POMOC PUBLICZNA" '+( if interests.includes("INNOWACJE  B+R  POMOC PUBLICZNA") then 'checked="checked"' else '')+'><span class="ml-3">INNOWACJE  B+R  POMOC PUBLICZNA</span></label></li><li><label><input type="checkbox" name="user_interests_' + id + '[]" value="SPRZEDAŻ I MARKETING" '+( if interests.includes("SPRZEDAŻ I MARKETING") then 'checked="checked"' else '')+'><span class="ml-3">SPRZEDAŻ I MARKETING</span></label></li><li><label><input type="checkbox" name="user_interests_' + id + '[]" value="ZARZĄDZANIE PRODUKCJĄ ZARZĄDZANIE FIRMĄ"'+( if interests.includes("ZARZĄDZANIE PRODUKCJĄ ZARZĄDZANIE FIRMĄ") then 'checked="checked"' else '')+'><span class="ml-3">ZARZĄDZANIE PRODUKCJĄ   ZARZĄDZANIE FIRMĄ</span></label></li><li><label><input type="checkbox" name="user_interests_' + id + '[]" value="GIEŁDA WSPÓłPRACY" checked="checked"'+( if interests.includes("GIEŁDA WSPÓłPRACY") then 'checked="checked"' else '')+'><span class="ml-3">GIEŁDA WSPÓŁPRACY</span></label></li></ul></div></div>'

        #        singleInput += '<div></div>'

        singleInput += '<div>'

        singleInput += '</div>'
        singleInput += '<div class="ml-auto  align-self-center text-right">'
        singleInput += '<a href="javascript:void(0)" class="btn btn-primary delete-user" data-row="' + id + '">Usuń</a>'
        singleInput += '</div>'
        singleInput += '</div>'

    container = '<div id="content-placeholder">'
    container = '<div id="user-list"></div>'
    container += '<div class="">'
    container += '<div class="d-flex justify-content-end">'
    container += '<a href="javascript:void(0)" class="btn btn-success  add-user mr-4">Dodaj osobę</a>'
    container += '<a href="javascript:void(0)" class="btn btn-primary save-user">Zapisz listę</a>'
    container += '</div>'
    container += '</div>'
    container += '</div>'
    $('input[id$="guestlist"]').after(container);
    fillContent = ()->
        content = ''
        items = JSON.parse(localStorage.getItem('user-list'))
        #        for item,k in items
        #            content += "Imię: " + item.name + ", Nazwisko: " + item.surname + ", stanowisko:" + item.position
        $('input[id$="guestlist"]').val(localStorage.getItem('user-list'))
    if $('input[id$="guestlist"]').length > 0 && localStorage.getItem('user-list')
        fillContent()
        $('input[id$="guestlist"]').val(localStorage.getItem('user-list'))
        items = JSON.parse(localStorage.getItem('user-list'))
        for item,k in items
            $('#user-list').append(createField(item.id, item.name, item.phone, item.position, item.email, item.birthday, item.nameday, item.interests));

    $('body').on('click', '.save-user', ()->
        collection = []
        $('.user-row').each((key, item)->
            id = $(item).data('id')
            name = $('input[id="user_name_' + id + '"]').val()
            phone = $('input[id="user_phone_' + id + '"]').val()
            position = $('input[id="user_position_' + id + '"]').val()
            email = $('input[id="user_email_' + id + '"]').val()
            birthday = $('input[id="user_birthday_' + id + '"]').val()
            nameday = $('input[id="user_nameday_' + id + '"]').val()
            interests = []
            $('input[name*="user_interests_' + id + '"]').each((subkey, subitem) ->
                if $(subitem).prop("checked")
                    interests.push($(subitem).val())
            )
            collection.push({
                'id': id,
                'name': name,
                'phone': phone,
                'position': position,
                'email': email,
                'birthday': birthday,
                'nameday': nameday,
                'interests': interests
            })
        )
        localStorage.setItem('user-list', JSON.stringify(collection))
        fillContent()
    )
    $('body').on('click', '.delete-user', ()->
        id = $(this).data('row')
        $('.user-row[data-id=' + id + ']').remove()

        collection = []
        $('.user-row').each((key, item)->
            id = $(item).data('id')
            name = $('input[id="user_name_' + id + '"]').val()
            surname = $('input[id="user_surname_' + id + '"]').val()
            position = $('input[id="user_position_' + id + '"]').val()
            collection.push({'id': id, 'name': name, 'surname': surname, 'position': position})
        )
        localStorage.setItem('user-list', JSON.stringify(collection))
        $('input[id$="guestlist"]').val(localStorage.getItem('user-list'))
    )
    $('body').on('click', '.add-food', ()->
        target = $(this).data('target')
        kind = $(this).data('kind')
        $(target).val(kind)
    )
    #    collectData = ()->

    $('body .add-user').on('click', ()->
        counter = $('#user-list > div').length + 1
        $('#user-list').append(createField(counter))
    )
    $('.slider-for').slick
        slidesToShow: 1
        slidesToScroll: 1
        arrows: true
        fade: true
        asNavFor: '.slider-nav'
    $('.slider-nav').slick
        slidesToShow: 3
        slidesToScroll: 1
        asNavFor: '.slider-for'
        dots: true
        centerMode: true
        focusOnSelect: true

# ---
# generated by js2coffee 2.2.0
